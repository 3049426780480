<template>
  <v-app>
    <v-main class="main">
      <router-view class="page"></router-view>
      <CookieConsent />
    </v-main>
  </v-app>
</template>

<script>

import CookieConsent from "@/components/common/CookieConsent.vue";

export default {
  name: 'App',
  components: {CookieConsent},

}
</script>
<style>

.main {
  background: linear-gradient(32deg, #ffffff, #ffc700) no-repeat fixed;
  background-size: cover;
  min-width: fit-content;
}

* {
  font-family: Inter, Helvetica, Arial, sans-serif;
}

</style>
