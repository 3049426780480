<template>
  <div v-if="!isConsentGiven" class="cookie-notice">
    <p>
      Используя данный сайт, вы даете согласие на использование файлов cookie,
      помогающих нам сделать его удобнее для вас.
      <a href="/privacy-policy" target="_blank">Политика обработки персональных данных</a>
    </p>
    <button @click="giveConsent" class="agree-button">Да, не показывать снова</button>
  </div>
</template>

<script>
export default {
  name: "CookieConsent",
  data() {
    return {
      isConsentGiven: false,
    };
  },
  mounted() {
    this.isConsentGiven = !!localStorage.getItem("cookie_consent");
  },
  methods: {
    giveConsent() {
      localStorage.setItem("cookie_consent", "true");
      this.isConsentGiven = true;
    },
  },
};
</script>

<style scoped>
.cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 340px;
  margin: 0 auto;
}

.cookie-notice p {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.cookie-notice a {
  color: #333333;
  text-decoration: underline;
}

.agree-button {
  background: #ffc107;
  color: #333333;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 16px;
  font-size: 14px;
}

.agree-button:hover {
  background: #ffc107;
}


@media (max-width: 768px) {
  .cookie-notice {
    padding: 15px;
    max-width: 100%;
    width: 100%;
    border: none;
  }

  .cookie-notice p {
    font-size: 12px;
  }

  .cookie-notice button {
    font-size: 12px;
    padding: 8px 16px;
  }

}

@media (min-width: 769px) {
  .cookie-notice {
    right: 20px;
    bottom: 20px;
    left: auto;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 16px;
  }

  .cookie-notice p {
    font-size: 13px;
  }
}
</style>
